import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom'

import DataFeedReport from './DataFeedComponent';
import DynamicReport from './DynamicComponent';
//import StaticReport from './StaticComponent';
//import TopBar from 'kubra-ux/dist/modules/Navigation/TopBar';
import logo from '../images/EXC_DP_RGB_PS.svg';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

const map_url = process.env.REACT_APP_MAP_URL;
const restore_url = process.env.REACT_APP_RESTORATION_URL;
const goto = process.env.REACT_APP_GOTO;
const viewId = process.env.REACT_APP_INTERNAL_VIEW;


class Main extends React.Component{
  render(){
    console.log("process.env:"+process.env.REACT_APP_SC5_API_ENDPOINT);
    const ReportPage =({match}) => {
        // if(match.params.areaId){
        return (
            <div className="container">
                {/*<h4>areaId: {match.params.areaId}</h4>*/}
                {/*<div className={`header map-header`}>*/}
                    {/*<TopBar*/}
                        {/*clientName="<a href'#'>Go to Map</a>"*/}
                        {/*isKubra={false}*/}
                        {/*isAdmin={false}*/}
                    {/*/>*/}
                {/*</div>*/}

                <div class = "header map-header" >
                  <div class = "topbar" >
                    <span class = "topbar__logo">
                      <img src={logo} height={100} padding={10}/>
                    </span>

                    <BrowserView>
                    <span class="topbar__right">
                      { match.params.viewId === viewId ? <a href={map_url+match.params.viewId+"?address="+encodeURI(match.params.areaId)+"&zoom=16"}> Go to Map </a> :<a href={goto+"?address="+encodeURI(match.params.areaId)+"&zoom=16"}> Go to Map </a>}
                    </span>
                    </BrowserView>
                    <MobileView>
                    <span class="topbar__right">
                      <a href={map_url+match.params.viewId+"?address="+encodeURI(match.params.areaId)+"&zoom=16"}> Go to Map </a>
                    </span>
                    </MobileView>
                  </div>
                </div>

                <div className="content">

                    <DynamicReport areaId={match.params.areaId} viewId={match.params.viewId}/>

                    <DataFeedReport areaId={match.params.areaId}/>

                    {/*<StaticReport areaId={match.params.areaId}/>*/}
                  <div>
                    <a href={restore_url}>Understanding the Restoration Process</a>
                  </div>

                </div>
            </div>
        );
    }

      return (
          <Router>
              {/*<Route path="/" component={HomePage}/>*/}
              <Route path="/:viewId/:areaId" component={ReportPage}/>
          </Router>
      )
  }
}
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
export default Main
